import React from 'react'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'

const Container = styled.div`
  width: 1000px;
  height: 250px;
  border: 2px solid white;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
`

const MobileContainer = styled(Container)`
  width: 80%;
  height: auto;
`

const Headline = styled.p`
  color: white;
  font-family: Roboto;
  font-size: 1em;
  font-weight: bold;
  margin-left: 25px;
  padding-top: 30px;
`

const MobileHeadline = styled(Headline)`
  padding-top: 10px;
`

const CaptionOne = styled.p`
  color: white;
  font-family: Roboto;
  font-size: 1em;
  margin-left: 25px;
`

const CaptionTwo = styled.p`
  color: white;
  font-family: Roboto;
  font-size: 1em;
  margin-left: 25px;
`

const Info = () => {
  return(
    <div>
      <MediaQuery minDeviceWidth={1225}>
        <Container>
          <Headline>YEBO MUSIC is an Artist Management and Music Company</Headline>
          <CaptionOne>Founded by Colin Finkelstein a Music industry Executive with over 30 years in the business.  Colin was the former COO of EMI Music  responsible for running day to day operations.  Labels included Capitol Records, Virgin, Astralwerks, Capitol Nashville, Capitol Christian and Blue Note Records.  Colin currently runs a Music Business Management company Cofink.</CaptionOne>
          <CaptionTwo>Our Name is derived from YEBO – A Zulu word meaning: YES.</CaptionTwo>
        </Container>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={1224}>
        <MobileContainer>
          <MobileHeadline>YEBO MUSIC is an Artist Management and Music Company</MobileHeadline>
          <CaptionOne>Founded by Colin Finkelstein a Music industry Executive with over 30 years in the business.  Colin was the former COO of EMI Music  responsible for running day to day operations.  Labels included Capitol Records, Virgin, Astralwerks, Capitol Nashville, Capitol Christian and Blue Note Records.  Colin currently runs a Music Business Management company Cofink.</CaptionOne>
          <CaptionTwo>Our Name is derived from YEBO – A Zulu word meaning: YES.</CaptionTwo>
        </MobileContainer>
      </MediaQuery>
    </div>
  )
}

export default Info

import React from 'react'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'

const Container = styled.div`
  width: 500px;
  height: 100px;
  border: 2px solid white;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  text-align: center;
`

const MailTo = styled.a`
  color: white;
  font-family: Roboto;
  font-size: 1.5em;
  line-height: 90px;
`

const EmailMobileContainer = styled(Container)`
  width: 275px;
`

const MobileMailTo = styled(MailTo)`
  font-size: 1em;
`

const Email = () => {

  return(
    <div>
      <MediaQuery minDeviceWidth={1225}>
        <Container>
          <MailTo href='mailto:info@yebomusic.com'>Info@yebomusic.com</MailTo>
        </Container>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={1224}>
        <EmailMobileContainer>
          <MobileMailTo href='mailto:info@yebomusic.com'>Info@yebomusic.com</MobileMailTo>
        </EmailMobileContainer>
      </MediaQuery>
    </div>
  )

}

export default Email

import React from 'react';
import Home from './Home';
import Contact from './Contact'
import About from './About'
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/contact'>
          <Contact />
        </Route>
        <Route exact path='/about'>
          <About />
        </Route>
        <Redirect from='*' to='/' />
      </Switch>
    </Router>
  );
}

export default App;

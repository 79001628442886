import React from 'react'
import Menu from './Menu'
import Yebo from './Yebo'
import Socials from './Socials'

const Home = () => {
  return(
    <div>
      <Menu />
      <Yebo />
      <Socials />
    </div>
  )
}

export default Home

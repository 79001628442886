import React from 'react'
import Yebologo from '../Home//YEBOlogo-notext.jpg'
import { Link } from "react-router-dom";
import Email from './Email'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

const HeaderLogo = styled.img`
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
`

const MobileHeaderLogo = styled(HeaderLogo)`
  width: 175px;
`
const Contact = () => {

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return(
    <div>
      { isDesktopOrLaptop && <Link to='/'><HeaderLogo alt='' src={Yebologo} /></Link> }
      { isTabletOrMobile && <Link to='/'><MobileHeaderLogo alt='' src={Yebologo} /></Link> }
      <Email />
    </div>
  )

}

export default Contact

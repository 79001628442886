import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faSpotify } from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components'


const SocialIcon = styled.li`
  padding: 10px;
  display: inline-block;
`

const Socials = () => {
  return(
    <div style={{textAlign: 'center'}}>
      <ul style={{paddingLeft: '0'}}>
        <SocialIcon><a href='https://www.facebook.com/yebomusicofficial/' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color='white' size='2x' icon={faFacebook} /></a></SocialIcon>
        <SocialIcon><a href='https://twitter.com/yebo_music' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color='white' size='2x' icon={faTwitter} /></a></SocialIcon>
        <SocialIcon><a href='https://www.instagram.com/yebomusic/' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color='white' size='2x' icon={faInstagram} /></a></SocialIcon>
        <SocialIcon><a href='https://open.spotify.com/user/yebo_music?si=iCJHbaJDRFScagNfuF64_Q' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color='white' size='2x' icon={faSpotify} /></a></SocialIcon>
      </ul>
    </div>
  )
}

export default Socials

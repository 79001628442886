import React from 'react'
import Yebologo from './YEBOlogo.jpg'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

const HomeLogo = styled.img`
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -250px;
`

const MobileLogo = styled(HomeLogo)`
  margin-top: 100px;
`

const Yebo = () => {

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return(
    <div>
      { isDesktopOrLaptop && <HomeLogo alt='' src={Yebologo} /> }
      { isTabletOrMobile && <MobileLogo alt='' src={Yebologo} /> }
    </div>
  )
}

export default Yebo
